// utils
import axios from '../../utils/axios';

const chatAppOrgRegister = async (org, user) => {
  try {
    const obj = {
      org_id: org._id,
      org_name: org.name,
      admin_name: user.full_name,
      admin_email: user.contact.email,
      mobile: `${user.contact.country_code}${user.contact.phone}`,
    };

    const response = await axios({
      method: 'post',
      url: `/lms_chat/api/auto_register`,
      data: obj,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { chatAppOrgRegister };
