// import axios from 'axios';
// // config
// import { HOST_API_KEY } from '../config-global';

// // ----------------------------------------------------------------------

// const axiosInstance = axios.create({
//   baseURL: HOST_API_KEY,
//   withCredentials: true,
//   crossDomain: true,
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

// export default axiosInstance;

import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
  withCredentials: true,
  crossDomain: true,
});

// Add a request interceptor to include `x-user-id`
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = localStorage.getItem('_id');
    let partnerId = '';
    if (
      localStorage.getItem('currentPartner') === 'undefined' ||
      localStorage.getItem('currentPartner') === 'null' ||
      localStorage.getItem('currentPartner') === undefined ||
      localStorage.getItem('currentPartner') === null
    ) {
      partnerId = localStorage.getItem('partnerId');
    } else {
      partnerId = JSON.parse(localStorage.getItem('currentPartner'))?._id;
    }

    if (userId) {
      config.headers['x-user-id'] = userId; // Attach the header
    }
    if (partnerId) {
      config.headers['x-partner-id'] = partnerId; // Attach the header
    }
    return config;
  },
  (error) => Promise.reject(error) // Handle errors
);

// Add a response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
